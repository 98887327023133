var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"rounded-xl overflow-hidden"},[_c('v-toolbar',{staticClass:"primary",attrs:{"tag":"div","flat":"","dark":"","tile":""}},[_c('v-text-field',{staticClass:"mr-2",style:({
        'max-width': '170px',
      }),attrs:{"append-icon":"mdi-magnify","label":"Search","hide-details":"","solo-inverted":"","dense":"","flat":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-select',{style:({
        'max-width': '170px',
      }),attrs:{"label":"Filter by type","items":_vm.appointmetsTypes,"item-text":"label","item-value":"value","hide-details":"","solo-inverted":"","dense":"","flat":""},model:{value:(_vm.type),callback:function ($$v) {_vm.type=$$v},expression:"type"}}),_c('v-spacer')],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.filteredAppointments,"items-per-page":10,"search":_vm.search,"custom-filter":_vm.searchFilter,"loading":_vm.loading},scopedSlots:_vm._u([{key:"item.start",fn:function(ref){
      var item = ref.item;
return [_c('v-layout',{attrs:{"column":""}},[_c('div',{staticClass:"text-caption"},[_vm._v(_vm._s(_vm.getDate(item.start)))]),_c('div',{staticClass:"text-caption"},[_vm._v(" "+_vm._s(_vm.formatShedule(item.start))+" - "+_vm._s(_vm.formatShedule(item.end))+" ")])])]}},{key:"item.type",fn:function(ref){
      var item = ref.item;
return [_c('span',{staticClass:"text-capitalize"},[_vm._v(_vm._s(item.data.type))])]}},{key:"item.patient",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(item.data.patient.fullname)+" ")]}},{key:"item.doctor",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(item.data.doctor.fullname)+" ")]}}],null,true)},[_vm._v(" "+_vm._s(/* Format shedule */)+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }